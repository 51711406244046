<template>
  <div class="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end">
        <div class="app-auth-background" :class="{'auth-background-light': !night, 'auth-background-dark': night}">
        </div>
        <div class="app-auth-container">
            <!-- <div class="logo">
                <a href="#"></a>
            </div> -->
            <p class="auth-description">Please sign-in to your account.</p>

            <div class="auth-credentials m-b-xxl">
              <div v-show="!ssoChecked" class="form-group col-6">
                <label for="signInEmail" class="form-label">Email Address</label>
                <input v-model="email" type="email" class="form-control" id="signInEmail" aria-describedby="signInEmail" placeholder="example@varsanpr.com" name="username"/>
                <span id="usernameError" class="error-feedback" />
              </div>

              <div v-show="ssoChecked" class="form-group col-6">
                <label for="signInPassword" class="form-label m-t-md">Password</label>
                <input v-model="password" type="password" name="password" class="form-control" id="signInPassword" aria-describedby="signInPassword" placeholder="password"/>
                <span id="passwordError" class="error-feedback" />
              </div>

              <br/>

              <button :disabled="checkingSso" class="btn btn-primary btn-sm" v-show="!ssoChecked" @click="checkForSSO">
                <span v-show="checkingSso" class="spinner-border spinner-border-sm"></span>
                Continue
              </button>

              <button class="btn btn-primary btn-sm" v-show="ssoChecked" @click="handleLogin">Sign In</button>

              <a href="/forgot" class="auth-link m-l-md">Forgot Password?</a>

              <p v-show="message" class="text-danger"> {{message}}  </p>

            </div>

        </div>

        <form id="ssoForm" style="display: none" action="https://api.varsanpr.com/api/auth/signin" method="POST">
          <input type="hidden" name="email" :value="email"/>
        </form>
    </div>
</template>

<script>
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import authService from '../services/auth.service';
import axios from 'axios';

export default {
  name: 'Login',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data(){
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
      redirect_url: this.$route.query.redirect,
      ssoChecked: false,
      email: "",
      password: "",
      checkingSso: false
    }
  },
  mounted() {
    console.log(this.night);
    console.log('Redirect URL: ' + this.redirect_url);
    console.log('Token:  ' + this.$route.query.token)

    if(this.$route.query.token){

      console.log(`Checking token ${this.$route.query.token}`);

      authService.checkToken(this.$route.query.token).then(() => {
        if(this.redirect_url){
          window.location.href = this.redirect_url + '?token=' + this.$route.query.token;
        }else{
          console.log(`No redirect`);
          this.$router.push('/');
        }
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch('auth/logout');
      });

    }
  },
  computed: {
    loggedIn(){
      return this.$store.state.auth.status.loggedIn;
    },
    night(){
      if(this.$store.state.auth.status.dark == undefined)
        return true;
      else
        return this.$store.state.auth.status.dark;
    }
  },
  created(){
    if(this.loggedIn){
      authService.checkToken(this.$store.state.auth.user.token).then(() => {
        if(this.redirect_url){
          window.location.href = this.redirect_url + '?token=' + this.$store.state.auth.user.token;
        }else{
          this.$router.push('/');
        }
      })
      .catch(() => {
        this.$store.dispatch('auth/logout');
      });
    }
  },
  methods: {
    handleLogin(){
      this.loading = true;

      this.$store.dispatch('auth/login', {username: this.email, password: this.password}).then(() => {
        if(this.redirect_url == undefined)
          this.$router.push('/')
        else
          window.location.href = this.redirect_url + '?token=' + this.$store.state.auth.user.token;
        
      },
      (error) => {
        this.loading = false;
        this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        if(this.message == "Password is not valid"){
          this.message = `Password is not valid. ${error.response.data.remaining} attempts remaining.`
        }else if(error.response && error.response.data && error.response.data.message){
          this.message = error.response.data.message;
        }else{
          this.message = "The email address or password entered is incorrect. Please try again.";
        }
      })
    },
    ssoLogin(){
      window.location.href = 'https://api.varsanpr.com/api/auth/signin' + '?redirect=' + this.redirect_url;
    },
    checkForSSO(){
      this.checkingSso = true;
      axios.post(`https://api.varsanpr.com/api/auth/sso`, {
        email: this.email
      })
      .then((response) => {
        if(response.data.sso == true){
          document.getElementById('ssoForm').submit();
        }
        this.ssoChecked = true;
      })
      .catch((error) => {
        console.log(error);
        this.ssoChecked = true;
      })
      .finally(() => {
        this.checkingSso = false;
      });
    }
  }
}
</script>

<style scoped>
.app-auth-background {
  height: 100vh;
}

.auth-background-light {
  background: url('../../public/images/vars_logo_light.png') no-repeat;
  background-size: 75%;
  background-position: center;
}
.auth-background-dark {
  background: url('../../public/images/vars_logo_dark.png') no-repeat;
  background-size: 75%;
  background-position: center;
}
</style>
