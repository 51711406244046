<template>
  <router-view/>
</template>

<script>
  export default {
    created: function() {
      let token;
      if(this.$store.state.auth){
        if(this.$store.state.auth.user){
          token = this.$store.state.auth.user.token;
        }
      }
      let url = window.location.href;
      let queryParams = url.split('?');
      // console.log('Query Params: ' + queryParams);
      if(queryParams.length > 1){
        // console.log('Query: ' + queryParams[1]);
        let query = queryParams[1];
        let queryParts = query.split('&');
        for(let i = 0; i < queryParts.length; i++){
          let queryPart = queryParts[i];
          let queryPartParts = queryPart.split('=');
          if(queryPartParts[0] === 'token'){
            token = queryPartParts[1];
            break;
          }
        }
      }
      // console.log('Token: ' + token);
      // console.log('Path: ' + window.location.pathname);
      if(!token && window.location.pathname !== '/login' && window.location.pathname !== '/forgot' && window.location.pathname !== '/reset'){
        // console.log('No token, logging out');
        localStorage.removeItem('user');
        localStorage.removeItem('jwt');
        this.$store.dispatch('auth/logout');
        this.$router.push('/login');
        return;
      }
      if(window.location.pathname === '/forgot' || window.location.pathname === '/reset'){
        return;
      }

      if(window.location.pathname === '/forgot' || window.location.pathname === '/reset'){
        // console.log('Forgot or reset password page, no token needed');
        return;
      }
      if(!token){
        // console.log('No token, redirecting to login');
        this.$router.push('/login');
        return;
      }
      let payload = JSON.parse(atob(token.split('.')[1]));
      let exp = payload.exp;
      let now = Date.now() / 1000;
      if(exp < now){
        // console.log('Token expired, logging out');
        localStorage.removeItem('user');
        localStorage.removeItem('jwt');
        this.$store.dispatch('auth/logout');
        this.$router.push('/login');
      }else{
        // console.log('Token valid');

        if(this.$store.state.auth.user){
          this.$store.dispatch('auth/checkToken', token)
          .catch(() => {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          });
        }
      }
    }
  }
</script>
